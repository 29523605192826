import './App.css';
import Table from 'react-bootstrap/Table';
import {ServerIntf} from './ServerIntf'

export function Players(props) {
    const essSelected = (ess) => {
        console.log("Players.ess", ess)
        ServerIntf.SetESS(ess)
    }
    
    return (
        <div>
            <div>
                <span className="table-title">Non EGM Players</span>
            </div>
            <Table striped bordered size="sm">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Wins</th>
                    <th>Recall?</th>
                </tr>
                </thead>
                <tbody>
                {props.players.map(function (player) {
                    if (player) {
                        let playerEss = ServerIntf.PlayerIDToESS(player.ID)
                        let selected = playerEss === props.ess
                        return (
                            <tr key={playerEss}>
                                <td>{player.ID}</td>
                                <td>{player.PlayerName}</td>
                                <td>{player.EndPrizeWins}</td>
                                <td>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch"
                                               checked={selected}
                                               onChange={() => essSelected(selected ? '' : playerEss)}/>
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                    return ""
                })}
                </tbody>
            </Table>
        </div>
    );
}

/*
type Player struct {
	// These identify the game
	ID         int
	PlayerName string
	EndPrizeWins int64 // Number of times this game won end prize
}
*/