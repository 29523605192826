import './Rules.css';

export function Rules() {
    return (
        <div className="container">
            <h1>Grab Bag Bingo Rules</h1>
            <h3>Effective May 30, 2024</h3>
            <p>
                Grab Bag Bingo is a bingo game played by two or more players. Players may play with or without electronic aids.
                Each player has a five-spot bingo card.  Each card contains three spots with numbers ranging from 1 to 99, one spot containing one of twelve Greek gods, and one spot containing one of forty-three constellation symbols.
                There are 101 bingo balls in Grab Bag Bingo.  One ball for each number from 1 to 99, a wild “God” ball that matches any of the twelve Greek god symbols, and a wild “Constellation” ball that matches any of the forty-three constellation symbols.
                A bingo session may commence whenever at least two players are available for play.  Grab Bag Bingo begins with the random drawing of a bingo ball.  If a numbered ball is drawn, any player whose card contains a spot with the drawn number may daub that spot.  If the wild God ball is drawn, then each player may daub their Greek god spot.  If the wild Constellation ball is drawn, then each player may daub their constellation spot.
                Play continues until some player daubs every spot on their card and calls “Bingo” and wins the game ending prize.
                In addition to the game ending prize, Grab Bag Bingo also includes interim prizes.  An interim prize is awarded to the first player who covers a unique pattern containing their Greek god spot and constellation spot.   A single player can win both an interim prize and the game ending prize.
                Grab Bag Bingo features two types of prizes: a game ending prize and an interim grab bag prize.
            </p>
        </div>
    );
}